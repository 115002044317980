@import url(https://fonts.googleapis.com/css?family=Roboto:500,700,900&display=swap);
/* -----------------------
      GLOBAL STYLES
----------------------- */

body,
html {
  margin: 0;
  font-family: "Roboto";
  font-weight: 500;
}
a,
button {
  outline: none;
}
a {
  text-decoration: none;
}
.header h2 {
  font-weight: 500;
}

/* -----------------------
      SEARCH STYLES
----------------------- */

.search-icon {
  position: absolute;
  color: #999999;
  margin-left: 6px;
  font-size: 10px;
}

/* -----------------------
      AGENCY PROFILE
----------------------- */
#loginbtn {
  position: absolute;
  color: white;
  background-color: #999999;
  padding: 5px;
  border: 1px white solid;
  border-radius: 10px;
  margin: 1vw;
  cursor: pointer;
  float: left;
}

div.AgEnCy {
  padding: 10px 16%;
  display: flex;
}
div.lefty,
div.righty {
  background-color: #f2f2f2;
  margin: 15px 0px;
}
.lefty img {
  width: 125px;
  height: 125px;
  border-radius: 100%;
  padding-right: 30px;
}
.lefty img,
.lefty h1 {
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.AgEnCy h1 {
  color: #b23633;
}
div.AgEnCy p {
  color: #999999;
  font-size: 20px;
  padding-left: 5px;
}

div.AgEnCy h2 {
  color: #b23633;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0 2px;
  margin: 0 2px;
}

div.righty {
  padding: 10px;
  padding-top: 20px;
  width: 60%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.lefty {
  padding: 10px;
  width: 50%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.toggle-container {
  background-color: #fefefe;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.service-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.service-container .service {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  margin-bottom: 25px;
}


/* -----------------------
      SERVICE CARD
----------------------- */


.service-col-2 {
  background-color: #f2f2f2;
  max-width: 300px;
  border-radius: 0 1vw 1vw 0;
}

.service-col-2 ul {
  padding-right: 40px;
  padding: 10px 20px;
}

.service-col-2 li {
  display: block;
  text-align: left;
  padding: 10px 0;
  font-size: 18px;
  color: #999999;
}

.service-col-2 a:not(.emailLink) {
  display: flex;
  color: #fefefe;
  background-color: #b23633;
  font-size: 17px;
  width: 11px;
  height: 11px;
  padding: 10px;
  margin: 15px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.service-col-2 ul li .editButton {
  float: right;
}

.service-col-2 ul {
  padding-right: 40px;
  padding: 30px 20px;
}
/*---*/

/* -----------------------
      LOGGED IN STYLES
----------------------- */
.loggedInHeader {
  display: flex;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}
.loggedInHeader span {
  display: flex;
  flex-direction: column;
  margin-bottom: 2vw;
}
.loggedInHeader a {
  padding: 1vw 5vw;
  font-size: 1.5vw;
  border-left: solid 0.15vw black;
  border-right: solid 0.15vw black;
  cursor: pointer;
  color: #fefefe;
  background-color: #b23633;
  text-transform: uppercase;
  font-weight: 700;
  z-index: 2;
  text-align: center;
}


.loggedInHeader span #downIcon {
  color: #b23633;
  margin-left: auto;
  margin-right: auto;
  margin-top: -3%;
  transform: rotate(90deg);
  font-size: 2vw;
}

.timeline {
  display: flex;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* -----------------------
        LOGIN PAGE
----------------------- */

div.LoginInput {
  background-color: #942e29;
  padding: 10px;
  text-align: center;
  color: #fefefe;
}

div.loginpg h1 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 40px;
}

div.loginpg h2 {
  font-size: 20px;
}

div.loginpg h2,
div.loginpg input {
  display: inline-block;
}

div.loginpg input {
  background-color: #fefefe;
  border: none;
  margin: 10px;
  border-radius: 15px;
  padding: 3px;
}

div.loginpg button {
  margin: 10px;
  color: white;
  background-color: #999999;
  width: 85px;
  border: none;
  border-radius: 15px;
  padding: 3px;
  outline: none;
  font-size: 12px;
}

div.loginpg a {
  text-decoration: underline;
  cursor: pointer;
  font-size: 10px;
}

div.SignupInput {
  background-color: #942e29;
  padding: 10px;
  text-align: center;
  color: #fefefe;
}

div.ForgotPassMod input {
  border: black 1px solid;
}


/* -----------------------
      CLIENT PAGE
----------------------- */

div.clientHd {
  padding: 10px 16%;
  display: flex;
}

div.clientLeft,
div.clientRight {
  background-color: #f2f2f2;
  margin: 15px 0px;
}

div.clientHd h1 {
  color: #b23633;
}

div.clientHd p {
  color: #999999;
  font-size: 20px;
  padding-left: 5px;
}

div.clientHd h2 {
  color: #b23633;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 0 2px;
  margin: 0 2px;
}

div.clientLeft {
  padding: 10px;
  padding-top: 20px;
  width: 60%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
div.clientLeft a {
  cursor: pointer;
  color: #666666;
  padding-left: 5px;
}

div.clientRight {
  padding: 10px;
  width: 50%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

a.edit {
  color: #b23633;
  font-size: 25px;
  padding: 10px;
  position: relative;
  left: 85%;
}

a.edit:hover {
  color: #942e29;
}

div.smallHead {
  display: flex;
  width: 100%;
}

div.timebtn {
  width: 50%;
  text-align: center;
  background-color: #b23633;
  border: 1px black solid;
  cursor: pointer;
  color: white;
}
div.timebtn:hover {
  background-color: #942e29;
}

div.timebtn:focus {
  background-color: #942e29;
}
div.timebtn h2 img {
  transform: scaleX(-1);
}

div.filebtn {
  width: 50%;
  text-align: center;
  background-color: #b23633;
  border: 1px black solid;
  cursor: pointer;
  color: white;
}
div.filebtn:hover {
  background-color: #942e29;
}

div.filebtn:focus {
  background-color: #942e29;
}

div.timelineMod {
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 5px 10px;
  margin: 10px 10px;
  display: flex;
  flex-direction: row;
}

div.timelineMod h1,
.timelineMod h2,
.timelineMod h3 {
  color: #b23633;
}

div.timelineMod p {
  color: #999999;
  padding-left: 5px;
}

div.timelineMod h2,
div.timelineMod h3 {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

div.timeLeft {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
}

div.timeline {
  background-color: #fefefe;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-items: center;
}

div.bigTime {
  padding: 10px 15%;
  display: flex;
  flex-direction: column;
}

div.timeline button {
  background-color: #999999;
  border: none;
  border-radius: 10px;
  padding: 7px;
  margin: 10px;
  color: white;
  width: 100px;
}

div.bigFile {
  display: flex;
  justify-content: center;
  padding: 10px;
}

div.files:not(filebody) {
  background-color: #fefefe;
  padding: 10px 17%;
  margin: 0;
}

div.fileMod {
  height: 280px;
  margin: 20px 35px;
  padding: 15px 20px;
  width: 350px;
  display: inline-block;
  border-radius: 15px;
  background-color: #f2f2f2;
}

div.fileMod h1,
a.fileCorner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
  margin: 0px;
}

div.fileMod h1 {
  border-bottom: #fefefe 3px solid;
}

div.fileMod h1:hover {
  color: #942e29;
}

div.fileMod h1,
.fileMod h2 {
  color: #b23633;
}

div.fileMod p {
  color: #999999;
  padding-left: 5px;
}

div.fileMod h2,
.fileMod p {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex-wrap: wrap;
  text-align: center;
  padding: 0 10px;
  padding-bottom: 0px;
  padding-top: 5px;
  justify-content: center;
  font-size: 20px;
  margin: 5px 0px;
}

a.fileCorner {
  font-size: 100px;
  color: #fefefe;
  text-align: left;
  padding-right: 30px;
}

div.trashy {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

div.trashy a {
  display: flex;
  color: #fefefe;
  background-color: #b23633;
  font-size: 15px;
  width: 9px;
  height: 9px;
  padding: 10px;
  text-align: center;
  margin: 10px 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

div.filebody {
  text-align: right;
  padding: 5px 0px;
}

div.filebody a.fileIcon {
  font-size: 40px;
  color: #999999;
}


div.bigClientDiv {
  background-color: #fefefe;
  padding: 10px;
}

div.anotherclientdiv {
  display: flex;
  padding: 20px 17%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

div.clientholder {
  background-color: #f2f2f2;
  border-radius: 15px;
  height: 150px;
  padding: 10px 25px;
  margin: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 650px;
}

.clientholder h1:hover {
  color: #942e29;
}

.clientholder h1 {
  font-size: 25px;
  text-transform: uppercase;
}

.clientholder h1,
.clientholder h2 {
  color: #b23633;
  margin: 2px 5px;
  padding: 0px 5px;
}

.clientholder h2 {
  font-size: 15px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 2px 0px;
}

.clientholder p {
  color: #999999;
  padding: 0 5px;
  margin: 2px 0px;
}

.clientholder div.right {
  display: flex;
  flex-direction: column;
}

.clientholder div.left {
  display: flex;
  flex-direction: column;
  padding-right: 30px;
}

/* Client Page end*/

